.chapterAccordion:hover {
    
    background-color: #e8f0fe; 
    border-color: #e8f0fe;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #185abc !important;
}

.activeTopicAccordion {
    
    background-color: #e8f0fe; 
    border-color: #e8f0fe;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #185abc !important; 

}



.content-enter {
    height: 0;
    overflow: hidden;
}

.content-enter-active {
    height: auto; /* Adjust this value to the maximum height you want to allow */
    transition: height 0.3s ease;
}

.content-exit {
    height: auto; /* Adjust this value to the maximum height you want to allow */
    overflow: hidden;
}

.content-exit-active {
    height: 0;
    transition: height 0.3s ease;
}
  